<script lang="ts" setup>
import {useAuthStore} from "~/stores/authUser";

const authStore = useAuthStore();
const localePath = useLocalePath()
const {t} = useI18n({
  useScope: 'global'
})

const menuItems = computed(()=>{
  const loggedInItems: any = [
    {
      label: t('nav.user.links.profile'),
      to: localePath('dealer-profile'),
    },
  ]

  if (authStore.isAdmin || authStore.isBackOffice) {
    loggedInItems.push({
      label: t('nav.user.links.admin'),
      to: localePath('admin'),
    })
    loggedInItems.push({
      label: t('nav.user.links.dealer'),
      to: localePath('dealer-cars'),
    })
  }

  loggedInItems.push({
    label: t('nav.user.links.logout'),
    itemProps: {
      'data-test': 'logout',
      onClick: () => authStore.logout(),
    }
  })

  return loggedInItems
})

</script>

<template>
  <div class="profile-wrapper">
    <AppDropdown
      v-if="authStore.loggedIn"
      :items="menuItems"
      width="210px"
    >
      <template #trigger="{toggle}">
        <div
          class="profile-wrapper"
          @click="toggle"
        >
          <div class="avatar">
            <img src="~/assets/img/avatar.png">
          </div>
          <div class="info-wrapper">
            <div class="user-name">
              {{ authStore.viewName }}
            </div>
          </div>
        </div>
      </template>
      <template
        #item="{item}"
      >
        <AppDropdownItem
          v-if="!item.to"
          v-bind="item.itemProps"
        >
          {{ item.label }}
        </AppDropdownItem>
        <NuxtLink
          v-else
          v-bind="item.itemProps"
          :to="item.to"
        >
          <AppDropdownItem>
            {{ item.label }}
          </AppDropdownItem>
        </NuxtLink>
      </template>
    </AppDropdown>
    <template v-else>
      <NuxtLink to="/auth/login">
        <FormKit type="button">
          {{ t('nav.user.links.login') }}
        </FormKit>
      </NuxtLink>
    </template>
  </div>
</template>

<style scoped lang="scss">
.profile-wrapper {
  display: flex;
  align-items: center;

  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 10px;

    .status {
      font-weight: 700;
      font-size: 10px;
    }
    .user-name {
      position: relative;
      top: 2px;
      font-size: 14px;
    }
  }

  .btn-arrow {
    position: relative;

    &:hover::before {
      opacity: 1;
    }

    &::before {
      position: absolute;
      content: "";
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: rgba(45, 45, 134, 0.1);
      opacity: 0;
      transition: opacity .3s;
    }
  }
}

</style>
